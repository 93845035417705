<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="事件类型" prop="eventcode" style="width: 25%">
              <a-select v-model="queryParams.eventcode">
                <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            <a-form-model-item label="重大影响" prop="biginfluence" style="width: 25%">
              <a-select v-model="queryParams.biginfluence">
                <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
              <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 25%">
                <a-select v-model="queryParams.plannedwork">
                  <a-select-option :value="0">否</a-select-option>
                  <a-select-option :value="1">是</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="事件编号" prop="eventnum" style="width: 25%">
                <a-input v-model="queryParams.eventnum"></a-input>
              </a-form-model-item>
              <a-form-model-item label="编号/位置" prop="location" style="width: 25%">
                <a-input v-model="queryParams.location"></a-input>
              </a-form-model-item>
              <a-form-model-item label="记录人" prop="recordman" style="width: 25%">
                <a-input v-model.trim="queryParams.recordman"></a-input>
              </a-form-model-item>
              <a-form-model-item label="查阅人" prop="checker" style="width: 25%">
                <a-input v-model="queryParams.checker"></a-input>
              </a-form-model-item>
              <a-form-model-item label="是否查阅" prop="ischecked" style="width: 25%">
                <a-select v-model="queryParams.ischecked">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option :value="0">未查阅</a-select-option>
                  <a-select-option :value="1">已查阅</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="deleteBatch()">删除</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="monitorpointname" slot-scope="value, record">
            <span v-if="!record.checkeraccount||!record.checkeraccount.includes(userInfo.useraccount)" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
          </span>
          <span slot="plannedwork" slot-scope="value">
            <span>{{value==0?'否':'是'}}</span>
          </span>
          <span slot="checkrecord" slot-scope="value,record">
            <a-button type="primary" size="small" @click="addCheckBackData(value, record)">已阅</a-button>
          </span>
          <span slot="checkrecords" slot-scope="value,record">
            <a-button type="link" size="small" @click="checkRecords(value, record)">查看</a-button>
          </span>
          <span slot="abnormaltype" slot-scope="value">
            <span>{{abnormalTypesMap[value]}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.eventrecordid">详情</a-menu-item>
                <a-menu-item v-if="deleteenable==1" :key="'delete-'+record.eventrecordid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal :visible="checkRecordsVisible" title="查阅记录" @cancel="checkRecordsVisible=false" :footer="null" width="800px">
      <a-table :columns="columns" :rowKey="(record,index)=> index" :data-source="checkData" style="width: 750px">
        <span slot="checktime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      </a-table>
    </a-modal>
    <a-modal :visible="detailVisible" title="未处理异常历史详情" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="系统自动生成" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="事件类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="管理类型" prop="managetype" style="width: 30%">
          <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发生时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker :disabled="true" v-model="detailParams.eventstarttime" show-time placeholder="" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.biginfluence!=1" label="特别事件报告编号" prop="specialeventnum" style="width: 30%">
          <a-input v-model="detailParams.specialeventnum" placeholder="" style="width: 100px;" :disabled="true"></a-input>
          <a-button size="small" style="height: 30px;margin-left: 2px" type="primary" @click="openSpecialEventModal">详情</a-button>
        </a-form-model-item>
        <a-form-model-item label="编号/位置" prop="location" style="width: 30%">
          <a-textarea v-model.trim="detailParams.location" placeholder="" cols="24" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventcode=='6'" label="设备类型" prop="devicetype" style="width: 30%">
          <a-cascader :disabled="true" :field-names="{ label: 'devicename', value: 'devicecode', children: 'children' }" :options="deviceOptions" v-model="deviceCascaderSelected" :load-data="deviceOnLoadChildren" placeholder="请选择设备类型" :change-on-select="true"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model.trim="detailParams.repairorder" placeholder="" style="width: 210px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model.trim="detailParams.description" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model.trim="detailParams.measure" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="记录人" prop="recordman" style="width: 30%">
          <a-input v-model="detailParams.recordman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time :disabled="true" placeholder="" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model.trim="detailParams.followreturn" placeholder="" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
          <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model.trim="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <special-event-modal :visible.sync="specialModalVisible" show-type="detail" :detailData="specialModalDetailData"></special-event-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import {finishSigns} from "@/json/wlwhistory";
import {addCheckBack, deleteEventrecord, getCheckBackListByCondition, getEventrecordListByCondition} from "A/wlw";
import deptselect from "@/mixins/deptselect";
import deviceselect from "@/mixins/deviceselect";
import specialEventModal from "V/businessManagement/reportForms/specialEvent/addOrEditModal.vue";
import {getSpecialEventListByCondition} from "A/businessmanagement";
export default {
  name: "unhandleAbnormal",
  components: {specialEventModal},
  mixins: [deptselect,pagination,deviceselect],
  data() {
    return {
      moment,
      detailVisible:false,
      checkRecordsVisible:false,
      userdepidCascaderSelected: [],
      deviceCascaderSelected: [],
      columns:[
        {
          title: '查阅者姓名',
          dataIndex: 'checkername',
          key: 'checkername',
          ellipsis: true,
        },
        {
          title: '联系电话',
          dataIndex: 'checkerphone',
          key: 'checkerphone',
          ellipsis: true,
        },
        {
          title: '查阅时间',
          dataIndex: 'checktime',
          key: 'checktime',
          ellipsis: true,
          scopedSlots: { customRender: 'checktime' },
        },
      ],
      checkData:[],
      finishSigns,
      bigInfluences:[],
      abnormalTypes:[],
      deviceTypes:[],
      abnormalTypesMap:{},
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      eventOptions:[],
      detailParams:{},
      queryParams:{
        monitorpointname:'',
        biginfluence:'',
        eventnum:'',
        eventcode:'',
        starttime:'',
        endtime:'',
        eventtype:902,
        eventstatus:1,
        plannedwork:'',
        checker:'',
        recordman:'',
        devicecode:'',
        ischecked:'',
      },
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 180,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '事件编号',
          dataIndex: 'eventnum',
          key: 'eventnum',
          ellipsis: true,
          width:100
        },
        {
          title: '管理类型',
          dataIndex: 'managetype',
          key: 'managetype',
          ellipsis: true,
          scopedSlots: { customRender: 'managetype' },
          width: 150
        },
        {
          title: '发生时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 150
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          width: 70
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
          width: 70
        },
        {
          title: '编号/位置',
          dataIndex: 'location',
          key: 'location',
          ellipsis: true,
          width: 120
        },
        {
          title: '计划性作业',
          dataIndex: 'plannedwork',
          key: 'plannedwork',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'plannedwork' },
          width: 80
        },
        {
          title: '情况描述',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: 240
        },
        {
          title: '处理措施',
          dataIndex: 'measure',
          key: 'measure',
          ellipsis: true,
          width: 220
        },
        {
          title: '报事/报修单号',
          dataIndex: 'repairorder',
          key: 'repairorder',
          ellipsis: true,
          width: 100
        },
        {
          title: '查阅人',
          dataIndex: 'checker',
          key: 'checker',
          ellipsis: true,
          align:'center',
          width: 150,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
              record.checker
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: '查阅',
          dataIndex: 'checkrecord',
          key: 'checkrecord',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecord' },
        },
        {
          title: '查阅记录',
          dataIndex: 'checkrecords',
          key: 'checkrecords',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'checkrecords' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'operation' },
        }
      ],
      deleteenable:'',
      managetype:'',
      usertype:'',
      isclickrow:false,
      specialModalVisible:false,
      specialModalDetailData:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "未处理异常"
    },
    breadcrumb() {
      const pages = [{name:"异常历史",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    // deviceCascaderSelected(val) {
    //   if(val && val.length) {
    //     this.queryParams.devicecode = val[val.length-1];
    //   }else {
    //     this.queryParams.devicecode = '';
    //   }
    // },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.deleteenable = logininfo.deleteenable;
      this.managetype=logininfo.managetype;
      this.usertype=logininfo.usertype;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(!this.isclickrow){
              record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.writetime=record.writetime?moment(record.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.arrivetime=record.arrivetime?moment(record.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
              record.biginfluence=record.biginfluence?record.biginfluence.toString():null
              record.systemnameid=record.systemnameid?record.systemnameid.toString():null
              record.feedback=record.feedback?record.feedback.toString():null
              if (record.devicecode) {
                this.initDeviceOptionsByDevicecode(record.devicecode)
              }
              this.detailParams=record
              this.detailVisible=true
            }
          },
        },
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        dispatcher:this.userInfo.rolename==='项目工程值班员'?1:0,
        rolename:this.userInfo.rolename,
        eventstatus:1,
        checkername:this.userInfo.username,
        checkeraccount:this.userInfo.useraccount,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.deviceCascaderSelected = [];
      this.queryInstallDate = [];
      this.eventcode='';
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.queryParams= {
        monitorpointname:'',
        biginfluence:'',
        eventnum:'',
        eventcode:'',
        starttime:'',
        endtime:'',
        eventtype:902,
        eventstatus:1,
        plannedwork:'',
        devicecode:'',
        recordman:'',
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      }else if(type == 'detail'){
        record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
        record.biginfluence=record.biginfluence?record.biginfluence.toString():null
        if (record.devicecode) {
          this.initDeviceOptionsByDevicecode(record.devicecode)
        }
        this.detailParams=record
        this.detailVisible=true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventrecordid);
      }).catch(()=>{
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    checkRecords(value, record){
      this.isclickrow=true
      this.checkRecordsVisible=true
      this.getCheckBackDataList(record.eventrecordid)
    },
    addCheckBackData(value,record){
      this.isclickrow=true
      let params={
        eventrecordid:record.eventrecordid,
        checkeraccount:this.userInfo.useraccount,
        checkername:this.userInfo.username,
        checkerphone:this.userInfo.phonenum,
        usertype:this.userInfo.usertype==0?this.usertype:this.userInfo.usertype,
        checktype:0,
        managetype:this.managetype,
        checktime:moment(new Date()).format("YYYYMMDDHHmmss")
      }
      addCheckBack(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
          this.isclickrow=false
          this.getTableData()
        }
      })
    },
    getCheckBackDataList(eventrecordid){
      getCheckBackListByCondition({eventrecordid: eventrecordid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.checkData=res.item
          this.isclickrow=false
        }
      })
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
    openSpecialEventModal(){
      getSpecialEventListByCondition({monitorpointnum:this.detailParams.monitorpointnum,special_event_num:this.detailParams.specialeventnum}).then(res=>{
        if(res&&res.returncode==='0'){
          if(res.item.length>0){
            this.specialModalDetailData=res.item[0]
            this.specialModalDetailData.enable=true
            this.specialModalVisible=true
          }else{
            this.$message.warning("系统没有相关的特别事件记录")
          }
        }
      })
    },
  },
}
</script>
<style scoped>

</style>